/* require('./bootstrap'); */

$(document).ready(function () {
    var isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    $("#left-toggler").click(function (event) {
        $(".left-nav").toggleClass("left-nav__hide");
        $("#left-toggler").toggleClass("active");
        $(".content__wrap").toggleClass("content__wrap__hide");
        $(".header__burger__wrap").toggleClass("active__wrap");
        $("body").toggleClass("lock");
        $(".header__burger__wrap").toggleClass("header__burger__wrap-min");
    });

    let body = document.querySelector('body');
    if (isMobile.any()) {
        body.classList.add('touch');


    } else {
        body.classList.add('mouse');
        /*  $("#left-nav.left-nav__hide").mouseover(function(){
             console.log("ok")
             $(".header__burger__wrap").toggleClass("header__burger__wrap-min2");
            });
            $("#left-nav.left-nav__hide").mouseout(function(){
              console.log("no")
              $(".header__burger__wrap").toggleClass("header__burger__wrap-min2");
             }); */

    }


    $('[data-toggle="tooltip"]').tooltip();

    $("#v-pills-1-tab").toggleClass("active");
    $("#v-pills-1").toggleClass("active show");


    let coordForm = document.querySelectorAll('.form__li');
    for (i = 0; i < coordForm.length; i++) {
        let thisInputForm = coordForm[i].children;
        $(thisInputForm[0]).val($(coordForm[i]).index());
    }

    let sv = $('#exampleFormControlSelect1').val();
    let sr = parseFloat(sv) + 32;
    $("#sorting").val(parseFloat(sr));


    /* транслит на латинский */
    function urlLit(w, v) {
        var tr = 'a b v g d e ["zh","j"] z i y k l m n o p r s t u f h c ch sh ["shh","shch"] ~ y ~ e yu ya ~ ["jo","e"]'.split(' ');
        var ww = '';
        w = w.toLowerCase();
        for (i = 0; i < w.length; ++i) {
            cc = w.charCodeAt(i);
            ch = (cc >= 1072 ? tr[cc - 1072] : w[i]);
            if (ch.length < 3) ww += ch; else ww += eval(ch)[v];
        }
        return (ww.replace(/[^a-zA-Z0-9\-]/g, '-').replace(/[-]{2,}/gim, '-').replace(/^\-+/g, '').replace(/\-+$/g, ''));
    }

    $(document).ready(function () {
        $('.mytranslit').bind('change keyup input click', function () {
            $('.mytranslitto').val(urlLit($('.mytranslit').val(), 0))
        });
    });
    /* ---транслит на латинский */


    /* выделение активного меню */
    $(function () {
        $('.main__menu [href]').each(function () {
            if (this.href == window.location.href) {
                $(this).addClass('main__menu__active');
            }
        });
    });

    /* ---выделение активного меню */

    function destroyUnitForMenu(unitId, menuForAppId) {
        $.ajax({
            type: "POST",
            url: "/main/menu_for_app/app_menu_units_destroy",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                menu_for_app_id: menuForAppId,
                unit_id: unitId,
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            },
            success: function (data) {
                if (data.status && data.status === 'ok') {
                    location.reload();
                } else if (data.status && data.status === 'error') {
                    console.log('error', data)
                } else {
                    console.log('Что-то пошло не так', data)
                }
            },
        });
    }

    $('#menuForAppForm').on("click", function (event) {
        if ($(event.target).attr('data-destroy')) {
            const menuForAppId = $(event.target).attr('data-item-id');
            const unitId = $(event.target).attr('data-unit-id');
            destroyUnitForMenu(unitId, menuForAppId)
        }
    });

});


